@font-face {
  font-family: "light";
  src: url(./assets/fonts/Poppins-Light.ttf);
}

@font-face {
  font-family: "regular";
  src: url(./assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "italic";
  src: url(./assets/fonts/Poppins-Italic.ttf);
}

@font-face {
  font-family: "medium";
  font-weight: 500;
  src: url(./assets/fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: "semi";
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: "bold";
  src: url(./assets/fonts/Poppins-Bold.ttf);
}

.container {
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.semi {
  font-family: "semi";
}

.container {
  width: 100% !important;
  max-width: none !important;
  padding-left: 96px !important;
  padding-right: 96px !important;
  
}

.container-flexible {
  padding-left: 96px !important;
  padding-right: 96px !important;
}

@media (max-width: 1000px) {
  .container {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .container-flexible{
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.8;
  }
  50% {
    opacity: .5;
  }
}

/* Table Extensions */
.data-table-extensions {
  padding: 1.7rem 1.2rem !important;
}
